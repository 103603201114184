<template>
  <div>
    <Navbar />
  </div>
  <div class="footer">
    <Footer />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  name: "App",
  components: {
    Navbar: defineAsyncComponent(() => import("@/components/shared/Navbar")),
    Footer: defineAsyncComponent(() => import("@/components/shared/Footer")),
  },
};
</script>

<style lang="scss">
.footer {
  position: absolute;
  width: 100%;
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "materialize-css/dist/css/materialize.min.css?17112021100930";
import "material-design-icons/iconfont/material-icons.css?17112021100930";
import "@/assets/scss/fonts.scss?17112021100930";
import VueRecaptcha from "vue3-recaptcha-v2";

createApp(App)
  .use(router)
  .use(VueRecaptcha, {
    siteKey: process.env.VUE_APP_SITE_KEY_CAPTCHA,
    alterDomain: false,
  })
  .mount("#app");

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/About"),
  },
  {
    path: "/products",
    name: "products",
    component: () => import("@/views/Products"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/Contact"),
  },
  {
    path: "/affiliate",
    name: "affiliate",
    component: () => import("@/views/Affiliate"),
  },
  {
    path: "/policies",
    name: "policies",
    component: () => import("@/views/Policies"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/components/NoPageFound"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
